@import "~bootstrap/scss/bootstrap-utilities";
@import '../variables/variables';

.nav-tabs .nav-link {  
	font-family: $font-family-inter;
}

.card {
  border: 1px solid #ebe9f1;
  box-shadow: none;

  &.selectable {    
    cursor: pointer;

    &:hover {
      box-shadow: 0 10px 20px rgba($black,.12), 0 4px 8px rgba($black,.06);
    }

    &.selected {
      box-shadow: 0 0 20px rgba($primary,.12), 0 0 8px rgba($primary,.06);
    }
  }
  
  .card-header {
      &.blue-banner {
        color: $white;
        justify-content: flex-start;
        font-size: 22px;
        background-color: $blue;
        margin-bottom: 1rem;
      }
  }
}

.btn {
  &.btn-bordered {
    border: 1px solid !important;
  }
}

.overflow-x-auto {
  overflow-x: auto;
}
