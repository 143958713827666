.secops-container {
    .col-sm-2, .col-sm-3 {
        --bs-gutter-x: 5px !important
    }

    .card-col {
        padding: 10px 14px 10px 14px; 
    }

    .summary-container {

        .metrics {
            .card {
                height: 100%;
                background-color: $card-background;
                border: 0px;
                margin-bottom: 0rem !important;

                .card-header {
                    padding: 5px 0px 0px 10px;
                    margin-bottom: 10px;

                    .form-label {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

                .card-body {
                    padding: 0px;
                    margin-left: 12px;
                    margin-right: 12px;

                    .form-label {
                        margin: 0px !important;
                    }

                    .badge {
                        padding: 15px 42px 15px 42px;
                        border-radius: 4px;
                    }

                    .tier-badge {
                        width: 20px;
                    }

                    .bg-warning {
                        background-color: $yellow !important;
                        color: #5e5873 !important;
                    }

                    .bg-light-info {
                        background: $cyan !important;
                        color: #5e5873 !important;
                        padding: 1.3rem 2.5rem !important;
                        border-radius: 90px;
                        color: $white
                    }

                    .progress {
                        border-radius: 0px;
                        height: 25px;
                        background-color: #D9D9D9;

                        .progress-bar {
                            border-radius: 0px;
                            background-color: #A3B7FF;
                        }
                    }

                    .security-benchmark-value {
                        padding-top: 3px;

                        .form-label:nth-child(1) {
                            padding-right: 5px;
                            font-weight: 600;
                        }
                    }

                    .prc-tier-btn {
                        border-radius: 4px;
                        box-shadow: none;
                        width: 120px;
                    }

                    .int-cloud-tier {
                        font-size: 14px;
                        font-weight: 400;
                        border-radius: 4px;
                        padding: 10px 30px;
                        color:$white;
                        background-color: $blue;
                    }
                }
            }

            .healtscore {
                padding: 0px 0px 0px 5px;
                background-color: $primary !important;

                .form-label {
                    color: $white;
                }

                .health-value {
                    font-size: 25px;
                }
            }
        }

        .charts {
            .card {
                height: 100%;
                background-color: $card-background;
                border: 0px;
                margin-bottom: 0rem !important;

                .card-header {
                    padding: 10px 0px 0px 10px;

                    .form-label {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

                .card-body {
                    padding: 0px !important;
                    display: flex;
                    justify-content: center;
                    .row {
                        width: 100%;
                    }
                }

                .progress {
                    border-radius: 0px;
                    height: 20px;
                    background-color: #D9D9D9;

                    .progress-bar {
                        border-radius: 0px;
                        background-color: #A3B7FF;

                      
                    }
                }

                .progress-bars {
                    margin-bottom: 3px; 
                    .progress-label {
                        width: 25%;
                    }   
                }
            }

            .barchart {
                padding: 0px !important;
                margin: 0px !important;
            }
        }

        .tables {
            .card {
                height: 100%;
                border: 0px;
                background: #F4F5F7;
                .card-header {
                    padding: 10px 0px 0px 10px;

                    .form-label {
                        font-size: 16px;
                        font-weight: 700;
                    }
                }

                .card-body {
                    padding: 10px 0px 0px 0px;
                    margin-left: 12px;
                }
            }

            .react-dataTable {
                border-radius: 4px;

                .rdt_Table {
                    background-color: #F4F5F7;
                    .rdt_TableHead {
                        .rdt_TableHeadRow {
                           
                            div:nth-child(2) {
                                padding: 0px !important;
                                text-align: center !important;
                            }

                            div:nth-child(3) {
                                padding: 0px !important;
                                text-align: center !important;
                            }
                        }
                    }

                    .rdt_TableBody {
                        .rdt_TableRow {
                            background: #F4F5F7 !important;
                            .rdt_TableCell {
                                .bg-secondary {
                                    background: rgba(130, 134, 139, 0.7) !important;
                                }
                            }
                        }
                    }
                }
            }

            .bg-warning {
                background-color: $yellow !important;
                color: #5e5873 !important;
            }

            .badge {
                width: 60%;
                border-radius: 4px;
            }
        }
    }

    .alers-incidents-container {
        
        .charts {
            .card {
                height: 85%;
                background-color: $card-background;
                border: 0px;

                .card-header {
                    .form-label {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

                .color-high {
                    color: $red
                }

                .color-med {
                    color: $yellow
                }

                .color-low {
                    color: #A3B7FF
                }

                .apexcharts-pie-series :hover {
                    filter: none
                }
                .apexcharts-tooltip-series-group {
                    background: #5F5F5F  !important;
                }
            }
        }

        .tables {
 
            .card {
                height: 100%;
                border: 0px;
                background: #F4F5F7 !important;
                .card-header {
                    padding: 10px 0px 0px 10px;

                    .form-label {
                        font-size: 16px;
                        font-weight: 700;
                    }
                }

                .card-body {
                    padding: 10px 0px 0px 0px;
                    margin-left: 12px;
                }
            }

            .rdt_Table {
                background: #F4F5F7 !important;
                div:nth-child(1)
                {
                    background: #F4F5F7 !important;
                }
                .rdt_TableBody {
                    .rdt_TableRow {
                        background: #F4F5F7 !important;
                    }
                }
            } 

            .react-dataTable {
                border-radius: 4px;
                .table-pagination {
                    background: #F4F5F7 !important;
               }
            }
        }
    }

    .compliance-container {
        .charts {
            .card {
                height: 85%;
                background-color: $card-background;
                border: 0px;

                .card-header {
                    .form-label {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

                .color-high {
                    color: $red
                }

                .color-med {
                    color: $yellow
                }

                .color-low {
                    color: #A3B7FF
                }
            }
        }

        .tables {
            .rdt_Table {
                background: #F4F5F7 !important;
                .rdt_TableBody {
                    .rdt_TableRow {
                        background: #F4F5F7 !important;
                        .rdt_TableCell {
                            height: auto;
                        }
                    }
                }
            } 

            .react-dataTable {
                border-radius: 4px;
                .table-pagination {
                    background: #F4F5F7 !important;
               }
            }
           
            .identified-threats-action {
                cursor: pointer;
            }

        }
    }

    .truncatetext > * {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.risk-assessment-modal {
 
    .sidebar-lg {
        width: 30% !important;
    }

    .sidebar-lg-70 {
        width: 75% !important;
    }
    .modal-body {
        h3 {
            color: $blue !important;
            font-size: 22px;
            font-weight: 700;
        }

        .form-label {
            font-size: 14px;
            font-weight: 400;
        }

        .modal-title {
            padding-left: 1rem;
        }

        .close-btn {
           cursor: pointer;
        }

        .modal-content {
            padding-left: 1rem;
            padding-top: 1rem;
            box-shadow: none;
            height: 90%;

           
        }

        .modal-content-data {
            height: 300px;
            .form-label {
                margin-top: 5px;
                font-weight: 400;
            }

            .btn-action-save {
                .bg-success {
                    background-color: $cyan !important;
                    color: $headings-color;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 40px;
                    padding: 13px;
                    cursor: pointer;
                }
            }
           
            .invalid-dmessage {
                color: $red
            }
        }

        .risk-assessment-chart {
            .form-label {
                font-weight: 600;
                font-size: 20px;
            }

            .bg-danger {
                font-size: 20px;
                font-weight: 600;
                border-top: 5px solid  rgba(255, 0, 0, 0.6);
                border-bottom: 5px solid  rgba(255, 0, 0, 0.6);
                background-color: rgba(255, 0, 0, 0.6) !important;
            }
            
            .bg-warning {
                font-size: 20px;
                font-weight: 600;
                color: black;
                border-top: 5px solid rgba(255, 159, 67, 0.6);
                border-bottom: 5px solid rgba(255, 159, 67, 0.6);
                background-color: rgba(255, 159, 67, 0.6) !important;
            }

            .bg-secondary {
                font-size: 20px;
                font-weight: 600;
                color: black;
                border-top: 5px solid rgba(255, 255, 0, 0.8);
                border-bottom: 5px solid rgba(255, 255, 0, 0.8);
                background-color: rgba(255, 255, 0, 0.6) !important;
            }

            .bg-success {
                font-size: 20px;
                font-weight: 600;
                color: black;
                border-top: 5px solid rgba(40, 199, 111, 0.6);
                border-bottom: 5px solid rgba(40, 199, 111, 0.6);
                background-color: rgba(40, 199, 111, 0.6) !important;
            }

            .bg-info {
                font-size: 20px;
                font-weight: 600;
                color: white;
                border-top: 5px solid rgba(227, 61, 148, 0.6);
                border-bottom: 5px solid rgba(227, 61, 148, 0.6);
                background-color: rgba(227, 61, 148, 0.6) !important;
            }

            .btn-action-save {
                .bg-success {
                    background-color: $cyan !important;
                    color: $headings-color !important;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 40px;
                    padding: 13px;
                    border:0px;
                    cursor: pointer;
                }
            }
        }
    }
    
   
}

.compliance-riskassessment-popover {
    .popover {
        background: transparent !important;
        box-shadow: none;
        .popover-inner {
            margin-left: -120px;
            margin-top: 125px;
            background: $body-color !important;
            .popover-body {
                .header, .body {
                    color: $white !important;
                }

                .close-popover {
                    color: $cyan;
                }

                .bg-success {
                    background-color: $cyan !important;
                    color: $headings-color;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 40px;
                    cursor: pointer;
                }

                .close-popover{
                    cursor: pointer;
                }

                
            }
        }
        
    }
   
}

 
@media (max-width: 1280px) {
    .secops-container {
        .card-col {
            padding: 6px; 
        }
        .summary-container {
            .metrics {
                .card {
                    .card-header {  
                        margin-bottom: 5px;
                        .form-label {
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                    .card-body {
                        margin-left: 6px;
                        margin-right: 6px;
                        .badge {
                            padding: 10px 20px 10px 20px;
                         }
                    }
                }
            }

            .charts {
                .card {
                    .progress-bars {
                        margin-bottom: 3px; 
                        .progress-label {
                            width: 50%;
                        }   
                    }
                }
            }
        
        }
    } 

     
}

