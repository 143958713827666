@import '../variables/variables';

.markdown-content { 
  position: relative;
  min-height: calc(100vh - 240px);
  margin: 0 1rem 1rem;
  line-height: 1.5rem;

  h1 {
    margin-bottom: 1.5rem;
    font-weight: 500;
  }
  h2, h3, h4 {
    margin-bottom: 1rem;
    font-weight: 500;
  }

  img {
    max-width: 100%;
  }
}

.collapsable-nav {
  list-style-type: none;
  padding: 0;

  li {
    padding: 5px 0;
    
    .nav-item-wrapper {
      display: flex;
      justify-content: space-between;     

      .nav-item {
        cursor: pointer;
        color: $body-color;
        transition: transform 0.25s ease;
        flex-grow: 4;

        &.active {
          font-weight: bold;
        }
      }

      &.active {
        font-weight: bold;
      }

      &.expanded {
        .accordion-chevron {
          transform: rotate(180deg);
        }
      }

      .accordion-chevron {
        cursor: pointer;
        transition: transform 0.25s ease;
      }
    }

    ul {      
      list-style-type: none;
      padding: 10px 0 0 15px;
    }
  }

}