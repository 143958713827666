.services-modal {
    height: 500px;
}

.service-filter {
    .customer-filter-label {
        padding-top: 7px;
    }

    select {
        width: 30% ;
    }

    .filter-active {
        color : $primary !important;
        
        .dropdown-toggle {
            color : $primary !important
        }

        button {
            .filter-active {
                color : $primary !important
            }
           
         };
     }
  
}

.service-content {     
     .card {
        border: 0px;
     }
     .table-row-count {
        bottom: 7px !important;
     }
}


 .customer-selection {
    .form-control, .input-group-text {
        border: 0px;
        border-bottom: 1px solid $primary;
        border-radius: 0px;
    }
 }


