@import '../variables/variables';


.nav-header {
    border-bottom: 2px solid $blue;
    padding: 1.8rem 0 1.8rem 0;
    .navbar-heading {
        color: $blue;
        font-family: $font-family-inter;
		font-size: 18px;
		margin: 0;
    }
}

.main-menu {
	.navbar-header { 
		height: auto;
    width: auto;
    margin-top: 10px;
    margin-bottom: 25px;
    .nav-item {
      width: 100%
    }
    
		.navbar-brand { 			
			padding: 0.7rem 0 0;
            cursor: pointer;
			.brand-logo {
                width: 100%;
                text-align: center;
				img {
					max-width: 220px;         
				}
			}
			.brand-text {
				display: none !important;
			}
		}
		.nav-toggle {
			display: none;
		}		
	}

	.main-menu-content {	
    .navigation {
			a.active {				
				@extend %cyan-banner;
        margin: 0px 2px;
        padding-left: 15px;
       .menu-item {
          padding-top: 2px
       }
			}
			li {
				ul {
					a.active {
						@extend %cyan-banner;
            padding-left: 250px;
					}
				}
 
			}
      .nav-item.has-sub.childActive {
        > a {
          color: $cyan !important;
        }
        > a::after {
          background-image: url('data:image/svg+xml,<svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 2.58558C18.5 3.06595 18.3164 3.54448 17.951 3.90384L10.7512 10.9866C10.0438 11.6796 8.92244 11.6704 8.22766 10.9627L1.02784 3.62882C0.324054 2.91377 0.324054 1.75318 1.02784 1.03629C1.73162 0.321237 2.86919 0.321237 3.57297 1.03629L9.52182 7.09592L15.4491 1.26731C16.1655 0.563255 17.3048 0.583424 17.9942 1.31131C18.3326 1.66884 18.5 2.12721 18.5 2.58558Z" fill="cyan"/></svg>') !important;
        } 
      }
		}
		.nav-item.has-sub.sidebar-group-active {
			> a {
				border-radius: 0 !important;
        color: $cyan !important;
			}
      > a::after {
        background-image: url('data:image/svg+xml,<svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 2.58558C18.5 3.06595 18.3164 3.54448 17.951 3.90384L10.7512 10.9866C10.0438 11.6796 8.92244 11.6704 8.22766 10.9627L1.02784 3.62882C0.324054 2.91377 0.324054 1.75318 1.02784 1.03629C1.73162 0.321237 2.86919 0.321237 3.57297 1.03629L9.52182 7.09592L15.4491 1.26731C16.1655 0.563255 17.3048 0.583424 17.9942 1.31131C18.3326 1.66884 18.5 2.12721 18.5 2.58558Z" fill="cyan"/></svg>') !important;
      }
      ul {
        li {
         
          a {
            padding-left: 47px;
          }
          a.active {
            padding-left: 55px;
            margin: 0px -13px;
          }
        }
      }
			li a i, 
			li a svg {
				width: auto;
				height: auto;
				font-size: auto;
			}
		}

    .menu-item  {
      font-size: 1.2rem;
    }
	}

  .nav-footer {
        margin-left: 15px;
        margin-bottom: 20px;
        text-align: left;
        font-size: 15px;
        font-family: $font-family-inter;
        font-weight: 400;
       a {
        color: $cyan;
        padding-right: 5px;
       
       }
       .footer-copy-icon {
        font-size: 27px;
        font-family: $font-family-inter;
        vertical-align: bottom;
       }
  }
}

.hover-navigation-container {
 
  .hover-navigation {
    background-color: $blue;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: $font-family-inter;
    ul {
      margin:0px;
      padding:0px;
      list-style-type: none;
      li {
        list-style-type: none;
        margin: 0 2px;
        padding: 10px 1px 10px 1px;
        a {
          color:$white;
          font-size: 1.2rem;
          padding: 13px 13px 13px 13px;
          background: linear-gradient(118deg, rgba(25, 75, 255, 0.7), rgba(25, 75, 255, 0.7));
        }
        a.active {
          @extend %cyan-banner;
          color:$white;
          padding: 10px 13px 10px 13px;
          background: linear-gradient(118deg, rgba(25, 75, 255, 0.7), rgba(25, 75, 255, 0.7));
        }
      }
    }
  }
  
}

.content-footer {
  border-top: 1px solid lightgray;
  .footer-info {
    padding-left: 10px;
    color:gray;
    text-decoration: underline;
    font-family: $font-family-inter;
    cursor: pointer
  }

  .footer-icon {
   span {
    height: 20px;
    width: 20px;
    font-family: $font-family-inter;
   }
  }
}

.nav-popover {
  .card { 
		margin-left: -6px; 
	}
	.popover {
		background: transparent !important;
		box-shadow: none;
	}
  .popover-body {
		border: 0px !important
	}
  .nav-hoverlabel {
    background-color: $cyan;
    margin-left: -6px;
    margin-top: 20px;
    padding: 0.3rem 1.5rem 0 1.5rem;
    p {
      font-size: 17px;
      font-family: $font-family-inter;
      padding-bottom: 0.3rem;
    }
  }
}

.nav-group-popover {
  .card { 
		left: 73px; 
    margin-top:-47px;
  
  }
  .popover {
		background: transparent !important;
		box-shadow: none;
    z-index: 0;
	}
}
 
 