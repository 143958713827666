/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import 'base/animations';
@import 'variables/variables';
@import 'vendor/react-dropzone';
@import 'vendor/react-select';
@import 'vendor/datatable';
@import 'vendor/sortable';
@import 'vendor/reactstrap';
@import 'vendor/flatpickr';
@import 'vendor/bootstrap';
@import 'skins/logicalis';
@import 'pages/service-portfolio';
@import 'pages/home';
@import 'pages/knowledge-base';
@import 'pages/report';
@import 'pages/services';
@import 'pages/secops';
a {
	color: $primary;

	&:hover {
		color: $primary;
	} 
}

.heading {
	font-family: $font-family-inter;
}

.banner-heading {
	@extend %red-banner;

	&.blue-banner {
		background-color: $blue;
	}
}

.content-header {
	.content-header-title {
		@extend %red-banner;
	}
}

.avatar {
	&.avatar-md {
		.avatar-content {
			width: 48px;
			height: 48px;

			.avatar-icon {
				width: 24px;
				height: 24px;
			}
		}
	}
}

.enabled-pointer-event {
	pointer-events: inherit !important;
	cursor: default;
}

.hexagon {
	svg {
		transform: rotate(-90deg);
	}

	.avatar {
		clip-path: polygon(25% 0,75% 0,100% 50%,75% 100%,25% 100%,0 50%) !important;
		transform: rotate(90deg);
		border-radius: 0%;
		z-index: 11;
		top: -10px;
	}

	.avatar-content {
		width: 65px;
    height: 65px;
	}

	.shadow {
		clip-path: polygon(25% 0,75% 0,100% 50%,75% 100%,25% 100%,0 50%) !important;
		transform: rotate(90deg);
		border-radius: 0%;
		position: absolute;
		background-color: $cyan;
		height: 90%;
		width: 100%;
		top: 8%;
		z-index: 10;
	}

	.dot {
		width: 3px;
		height: 3px;
		display: block;
		background-color: $primary;
		position: absolute;
		bottom: -12%;
	
		&:nth-of-type(1) {
			bottom: 27%;
			left: -10%;
		}
		
		&:nth-of-type(2) { left: -10%; }
		&:nth-of-type(3) { left: 25%;	}
		&:nth-of-type(4) { right: 25%; }
		&:nth-of-type(5) { right: -10%; }
		
		&:nth-of-type(6) {
			bottom: 27%;
			right: -10%;
		}
	}
}

.breadcrumb-wrapper {
	height: 100%;

	nav {
    height: 100%;
    display: flex;
    align-items: center;
		font-family: $font-family-inter;
	}
}

.fallback-spinner {
	min-height: 300px;

	.fallback-logo {
		animation: spin 5s infinite;
		margin-top: -60px;
	}
}



.header-navbar {
	.navbar-nav {
		.show {
			position: relative;
		}
	}
 
}

.scroll-top {
	bottom: 75px; 
	right: 18px;
	visibility: hidden;
	opacity: 0;
	transition: all .2s;

	&.show {			
		visibility: visible;
		opacity: 1;
	}
}

.full-height-col {
	min-height: calc(100vh - 250px);
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 30px;

	> div {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
	}
}

.embedded-power-bi {
	min-height: calc(100vh - 250px);
	iframe {
		min-height: calc(100vh - 250px);
	}

	&.tabs {
		min-height: calc(100vh - 361px);
		iframe {
			min-height: calc(100vh - 361px);
		}
	}	
}

.accordion {
	.card-header {
		cursor: pointer;

		svg.accordion-chevron {
			transition: all 300ms linear 0s;
		}

		&.expanded {
			svg.accordion-chevron {
				transform: rotate(180deg);
			}
		}
	}
}

.overlay-spinner {
	background: rgba($white, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: none;
	justify-content: center;
	align-items: center;

	.fallback-spinner {
		min-height: auto;
		
		.fallback-logo {
			margin-top: 0;
		}
	}

	&.show {
		display: flex;
	}
}

.mini-scrollbar {	
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent; 
	}
	 
	&::-webkit-scrollbar-thumb {
		background-color: rgba($gray-100, .2); 
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: rgba($gray-100, .8); 
	}
}

.dropdown-selection {

	width: 250px;

	.dropdown-menu {
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	button {
		width: 100% !important;
		border-radius: 5px;
		text-align: left;
		background-color: transparent !important;
		color: #5f5f5f;
		padding-left: 10px;
		white-space: nowrap;
		overflow: hidden;
		border-color: #d8d6de !important;
		&:hover {
			box-shadow: none !important;
		}
		
		&:focus {
			background-color: transparent !important;
		}
		.icon {
			color: #5f5f5f
		}
		.dropdown-label {
			overflow: hidden;
			text-overflow: ellipsis;
			color: $primary
		}
	}

	.dropdown-toggle {
		box-shadow: none;
		&:focus {
			background-color: transparent !important;
		}

		&::after {
			display: none;
		}
	}

	.dropdown-item {
		text-overflow: ellipsis;
	}
	
	.dropdown-header{
		padding: 0.5rem 7px
	}

}