//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
@import '../../../@core/scss/base/bootstrap-extended/variables';

$red: #FF1414;
$blue: #194BFF;
$yellow: #FFD955;
$cyan: #00FFCD;
$primary: $blue;
$danger: $red;
$body-color: #5F5F5F;
$headings-color: #5F5F5F;
$card-background: #F4F5F7;
$error: #ea5455;

$font-family-roboto-slab: 'Roboto Slab', serif, Helvetica, Arial !default;
$font-family-inter: 'Inter', sans-serif;
 

%red-banner {
	color: $white !important;
	font-family: $font-family-inter;
	background-color: $red;
	padding: 13px 10px;
	display: inline-block;
}

%blue-banner {
	font-family:$font-family-inter;
	border-radius: 0;
	background-color: $primary;
	color: $white;
}

%cyan-banner {
	font-family: $font-family-inter;
	border-radius: 0;
	background-color: $cyan;
	border-left: 5px solid $cyan;
	border-right: 5px solid $cyan;
	color: $cyan
}