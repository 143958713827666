@import '../variables/variables';

.react-dataTable  {
  .rdt_Table {  
    .rdt_TableHead {
      .rdt_TableHeadRow {
        background-color: $primary;
        border: none;

        .rdt_TableCol .rdt_TableCol_Sortable {
          color: white;
          font-family: $font-family-inter;
          text-transform: none;
          font-weight: 600;
        }
      }
    }
  }
  &.table-hover {
    .rdt_TableRow:hover {
      cursor: pointer;
      color: #6e6b7b;
      background-color: $table-hover-bg;
    }
    &.rdt_TableRow
    &.not-clickable { 
      .rdt_TableRow:hover {
        cursor: default;
      }
    }
  }

  .expandable-icon {
    background-color: $primary;
    color: $white;
  }
} 

.page-item.next-item .page-link, 
.page-item.prev-item .page-link {
  transition: all .2s ease-out;
}

.table-row-size {
  padding-left: 20px;
  font-weight: 400;
  font-size: 14px;

  span {
    padding-top: 15px;
  }

  select {
    box-shadow: none;
    border: 0px;
    padding-top: 1px;
    padding-bottom: 0;
    width: 65px;
    
    &:focus {
      box-shadow:none;
    }
  }
}