@import '../variables/variables';

.sp-include-compare .rdt_TableRow div {
	position: relative;
	min-width: 0% !important;
}

.sp-table {
	.rdt_TableCol_Sortable {
		.not-sorted-by {
			&+span.__rdt_custom_sort_icon__ svg {
				opacity: 0.4;
				transform: rotate(0deg);
			}
		}

		.sorted-by {
			&+span.__rdt_custom_sort_icon__ svg {
				opacity: 1;
			}
		}
	}	

	.rdt_TableRow {
		button {
			&:disabled .expandable-icon {
				display: none;
			}

			&:first-child {				
				&:hover:not(:active) {
					fill: transparent !important;
					background-color: transparent !important;
				}

				&:focus {
					background-color: transparent !important;
				}
			}
		}
	}

	.rdt_ExpanderRow {
		border-left: 1px solid $blue;
		margin-left: 23px;
		width: auto;
	}

	.sp-table-child {
		margin-left: 30px !important;
		width: auto;
	
		.rdt_TableRow {
			border-bottom: 1px solid rgba(0,0,0,.12);
		}
	}	

	.truncatetext {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	
}

.sp-includetable {
	.rdt_TableRow {
		border: 0;
		min-height: 0
	}
	.rdt_TableHead {
		display: none;
	}
}

.sp-content {
	margin-left: 25px;
}

.sp-header {
	font-family: $font-family-inter;
	margin-top: 0.5rem !important;
}

.sp-dc-rate-fill {
	fill: aqua !important;
	color: aqua !important;
}

.sp-tooltip-content {
	color: $black;
	font-size: 13px !important;
	padding: 0 0 0 0
}

.arrow {
	display: none !important;
}

.sp-border-lightgray {
	border-color: lightgray !important
}

.sp-tooltip-icon {
	background-color: transparent !important;
	cursor: default;
}

.sp-detail-header-icon {
	background-color: transparent !important;
	cursor: default;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	margin-top: 3px;
}

.sp-popover {	
	.card { 
		margin: 0;
	}

	.popover {
		background: transparent !important;
		box-shadow: none;
	}

	.popover-body {
		border: 0px !important
	}

	.sp-popover-card-header {
		padding: 0.5rem !important
	}
}

.sp-detail-card-header {
	padding: 0.5rem !important
}

.sp-chart {
	font-family: $font-family-inter;
}

.sp-details-side-container {
	background-color: #F5F7FC !important;
	border-radius: 10px;
	margin-bottom: 10px;
}

.sp-details-side-container-dependant {
	background-color: $red !important;
	border-radius: 10px;
	padding-left: 24px;
	padding-right: 24px;
}

.sp-details-side-content {
	margin: 1px
}

.sp-details-side-content-devider {
	border-bottom: 1px solid lightgray;
}

.sp-details-side-content .row {
	padding-left: 0 !important;
}

.selected-compare svg {
	fill: #389CFF
}

.sp-details h3,
h4 {
	font-weight: 700 !important;
}
 
.sp-filter {
	.select__indicator svg {
		color:lightgray !important
	}
}