@import '../variables/variables';

.dropzone {
  display: flex;
  cursor: pointer;
  min-height: 150px;
  align-items: 'center';
  justify-content: center;
  background-color: $body-bg;
  border: 1px dashed $secondary;
  border-radius: $border-radius;
}
