@import '../variables/variables';

.reports-dashboard {
    .card {
        width: 100%;
        height: 100%;
         box-shadow: 1px;
         border-radius: 10px;
         box-shadow: none;
         border: 1px solid hsla(0, 0%, 92.5%, 0.7);
         .row {
            .dashboard-card-footer {
                font-size: 0.7rem;
                margin: 10px;
                font-family: $font-family-inter;
                font-size: 12px !important;
                .footer-content {
                    display: flex;
                    justify-content: space-between;
                    .footer-refresh {
                        padding-top: 5px;
                    }
                    
                    .view-btn {
                        color: $white;
                        height: 26px;
                        width: 83px;
                        border-radius: 20px;
                        padding: 7px 20px 5px 20px;
                        cursor: pointer;
                        font-size: 12px !important;
                    }
                }
                
            }
        }

        .card-header{
            padding-left: 10px !important;
            font-size: 22px;
            font-family: $font-family-inter;
            color: #808080;
        }

        .card-body {
            img {
                vertical-align: bottom !important;
                margin-right: 20px;
                margin-top: -23px !important
            }
        }
    }
}

.report-power-bi {
    height: 100vh;
    width: 100vh;
    iframe {
        min-height: 100%;
        position: absolute;
        height: 90% !important;
        width: 90% !important;
    }
} 

.ra-container {
    .card {
        .nav-item {
            a {
                color:gray;
            }
        }
    }

    
}

.ra-add-container {
    .role-selector {
        height: 200px !important;
    }

    .role-selector-container {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.ra-modal {
    max-width: 1000px !important;
    .ra-select-btn {
        padding-top: 55px;
        padding-left: 25px;
    }

    .header-divider {
        border-top: 1px solid #d8d6de;
        text-align: left;
        margin-left: 2px;
        margin-right: 2px;
    }
}

.reports-container {
    svg {
        height: 30px !important;
        width: 20px !important;
        cursor: pointer;
        path {
            stroke-width: 1;
        }  
    };
    

    .table-svg-icon {
        svg {
            path:nth-child(2) {
                opacity: 0.5;
            }
        }
    };

    .tiles-svg-icon {
        svg {
            path:nth-child(3) {
                opacity: 0.5;
            }
        }
    }

    .icon-label {
        padding-top: 5px;
    }
}
