
.select__multi-value {
  margin-top: 0.15rem !important;
}

.select__control {
  border-radius: 0%;
}

.select__menu {
  border-radius: 0%;
}