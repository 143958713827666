@import '../variables/variables';



.home-container {
    margin-left: 0px;
    margin-right: 0px;
    font-family: $font-family-inter;
    color: gray;
  
  
    .home-welcome {
        font-size: 16px;
    }

    .card-container {
        display: contents;
    }

    .card-header {
        padding: 1rem 1.5rem;
        .icon {
            padding-right: 40px;
        }
      
    }

    .btn-gotopage {
        text-align: right;
        padding-right: 10px;
        .arrow-btn {
            cursor: pointer;
            border-radius: 20px;
            margin-bottom: 0.5rem !important;
        }
        
        .disable-badge {
            pointer-events:none;
        }
    }

    .card-content {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        height: 100%;
        font-size: 16px;
        .listitem {
            ul {
                margin:0px !important
            }
        };
    } 
}