@import '../variables/variables';
@import '../base/animations';


.input-group-merge {
  .autocomplete-container {
    .form-control {
      margin-left: -1px;
      padding-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:not(:last-child) {
        border-right-width: 1px !important;
        border-right-style: solid !important;
        border-right-color: inherit !important;
      }
    }
  }
}

.modal {
  .modal-header {
    @extend %blue-banner;
    .modal-title {
      color: $white;
    }
  }

  .modal-content {
    border-radius: 0%;
  }
}

.badge {
	font-family: $font-family-inter;
  font-weight: normal;
}

.btn { 
  transition: background .4s ease-out;
}

.btn-primary {
	@extend %blue-banner;
}

.btn-secondary,
.btn-danger {
	font-family:$font-family-inter;
	border-radius: 0;
}

.pulse-primary {
  animation: pulse-primary 2s 1;
}

.table  {
  thead {
    tr {
      border: none;

      th {        
        background-color: $primary !important;
        color: white;
        font-family: $font-family-inter;
        text-transform: none;
        font-weight: normal;
      }
    }
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: $primary;
  background-color: lighten($primary, 40%);
}

.form-switch {
  .form-label {
    line-height: 1.8rem;
    margin-left: 0.3rem;
  }
}

.btn-group {
  &.invalid {
    .btn {
      border-color: $danger !important;
    }
  }
}

.is-invalid {
  color: $error;
  h5 {
    color: $error;
  }
}

.spinner-xs {
  width: 0.7rem;
  height: 0.7rem;
}